import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Flex,
  Spacer,
  Heading,
  Input,
  Button,
  Text,
  Box,
  Checkbox,
  Stack,
  Img,
  InputGroup,
  InputRightElement,
  VStack,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { HideIcon, ShowIcon } from 'src/icons';
import { login, logout, clearStatus, refreshToken, getUserDetails } from 'src/redux/auth/actions';
import {
  selectLoginStatus,
  selectRememberMe,
  selectForgetPasswordMessage,
  selectMoodleSession,
  selectLoginLoading,
  selectLoginErrorMessage,
  selectLoginStatusCode,
  selectLoginRole,
  selectUserDetailsStatusCode,
  selectAccountVerified,
} from 'src/redux/auth/selectors';
import TavisLogo from 'src/images/tavis_logo.svg';
import Background from 'src/images/img_login_register.png';
import SignInWithGoogle from 'src/components/SocialLogin/SignInWithGoogle';
import SignInWithApple from 'src/components/SocialLogin/SignInWithApple';
import SignInWithFacebook from 'src/components/SocialLogin/SignInWithFacebook';
import ForgotPassword from './ForgotPassword';

function Login(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const needClearStatus = useRef(false);
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const [firstLogin, setFirstLogin] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const getRefreshToken = useRef(false);

  const loginStatus = useSelector(selectLoginStatus);
  const loginLoading = useSelector(selectLoginLoading);
  const loginStatusCode = useSelector(selectLoginStatusCode);
  const errorMessage = useSelector(selectLoginErrorMessage);
  const rememberMeStatus = useSelector(selectRememberMe);
  const forgetPasswordMessage = useSelector(selectForgetPasswordMessage);
  const moodleSession = useSelector(selectMoodleSession);
  const loginRole = useSelector(selectLoginRole);
  const userDetailsStatusCode = useSelector(selectUserDetailsStatusCode);
  const accountVerified = useSelector(selectAccountVerified);

  useEffect(() => {
    if (forgetPasswordMessage) {
      alert(forgetPasswordMessage);
      dispatch(clearStatus());
    }
  }, [dispatch, forgetPasswordMessage]);

  useEffect(() => {
    if (userDetailsStatusCode === 200) {
      dispatch(refreshToken());
      setFirstLogin(true);
    } else if (userDetailsStatusCode === 401) {
      dispatch(clearStatus());
      setFirstLogin(false);
      getRefreshToken.current = false;
    }
  }, [userDetailsStatusCode]);

  useEffect(() => {
    if (moodleSession) {
      document.cookie = `MoodleSession=${moodleSession}; domain=${process.env.REACT_APP_BASE_URL}; path=/; secure; SameSite=None`;
    }

    if (!firstLogin && !rememberMeStatus) {
      dispatch(logout());
    } else if (loginStatus && !getRefreshToken.current && !firstLogin) {
      dispatch(getUserDetails());
      getRefreshToken.current = true;
    } else if (loginStatus && firstLogin) {
      if (loginRole === 'student') {
        needClearStatus.current = false;
        history.push('/');
      } else {
        setFirstLogin(false);
        dispatch(clearStatus());
        alert('You do not have permission to access it');
      }
    }
  }, [dispatch, loginRole, loginStatus, rememberMeStatus, firstLogin, history, moodleSession]);

  useEffect(() => {
    if (loginStatusCode === 403) {
      needClearStatus.current = true;
      return () => {
        if (needClearStatus.current) {
          dispatch(clearStatus());
        }
      };
    }

    if (
      (loginStatusCode === 422 || accountVerified === false) &&
      getRefreshToken.current === false
    ) {
      history.push({
        pathname: '/email-verification',
        state: { email: email },
      });
    }
  }, [loginStatusCode, accountVerified]);

  const onClickLogin = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setFirstLogin(true);

    dispatch(
      login({
        email,
        password,
        role: 'student',
        rememberMe,
      }),
    );
  };

  return (
    <Flex
      h={{
        base: '100%',
        sm: '100vh',
      }}>
      <Stack
        display={{
          base: 'none',
          lg: 'flex',
        }}
        direction="column"
        align="center"
        justify="center"
        spacing={20}
        w="40%"
        h="100%"
        overflow="hidden">
        <Img src={TavisLogo} width="328px" />
        <Img src={Background} />
      </Stack>
      <Stack
        color="white"
        backgroundColor="#65CD7D"
        h="100vh"
        w={{
          base: '100%',
          lg: '60%',
        }}
        direction="column"
        align="center"
        justify="center"
        spacing={8}
        m="auto"
        px="20px"
        py={{
          base: '450px',
          md: '20px',
        }}>
        <Box
          as="form"
          onSubmit={onClickLogin}
          minW={{
            base: 'auto',
            md: '550px',
          }}
          maxW="700px">
          <Heading
            as="h1"
            fontSize={34}
            fontWeight="bold"
            mb={10}
            textAlign="center"
            textTransform="capitalize">
            {t('welcome_to_tavis')}
          </Heading>
          <Text mb={2} fontSize={16} fontWeight="semibold">
            {t('email')}
          </Text>
          <Input
            required
            type="email"
            mb={5}
            bg="white"
            w="100%"
            color="black"
            value={email}
            borderRadius="full"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Text mb={2} fontSize={16} fontWeight="semibold">
            {t('password')}
          </Text>
          <InputGroup mb={5}>
            <Input
              required
              type={showPassword ? 'text' : 'password'}
              bg="white"
              color="black"
              borderRadius="full"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <InputRightElement cursor="pointer" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <ShowIcon /> : <HideIcon color="black" />}
            </InputRightElement>
          </InputGroup>
          {errorMessage && (
            <Text color="red" mb={5}>
              {errorMessage}
            </Text>
          )}
          <Flex justifyContent="center">
            <Button
              isLoading={loginLoading}
              p={5}
              w="50%"
              bg="none"
              fontSize={{
                base: 16,
                md: 27,
              }}
              fontWeight="bold"
              borderRadius="full"
              border="4px solid white"
              type="submit"
              _active={{ backgroundColor: '#2F9B50' }}
              _hover={{ backgroundColor: '#2F9B50' }}>
              {t('sign_in')}
            </Button>
          </Flex>
          <Flex justifyContent="center" mt="4">
            <Button
              p={5}
              w="50%"
              bg="none"
              fontSize={{
                base: 16,
                md: 27,
              }}
              fontWeight="bold"
              borderRadius="full"
              border="4px solid white"
              _active={{ backgroundColor: '#2F9B50' }}
              _hover={{ backgroundColor: '#2F9B50' }}
              onClick={() => history.push('/register')}>
              {t('create_account')}
            </Button>
          </Flex>
        </Box>
        <Box
          minW={{
            base: 'auto',
            md: '550px',
          }}
          maxW="700px">
          <Flex
            w="100%"
            mb={12}
            direction={{
              base: 'column',
              md: 'row',
            }}
            align="center">
            <Checkbox
              defaultIsChecked
              size="sm"
              onChange={() => setRememberMe(!rememberMe)}
              justifyContent="center">
              {t('remember_me')}
            </Checkbox>
            <Spacer />
            <ForgotPassword />
          </Flex>
          <VStack spacing={5} mt={6}>
            <SignInWithGoogle setFirstLogin={() => setFirstLogin(true)} />
            <SignInWithFacebook setFirstLogin={() => setFirstLogin(true)} />
            <SignInWithApple />
          </VStack>
        </Box>
      </Stack>
    </Flex>
  );
}

export default Login;
